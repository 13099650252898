import React, { PureComponent } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  FormText,
} from "reactstrap";
import Error from "./Error";

import printError from "../errors";
import NetworkService from "../NetworkService";

class OTP extends PureComponent {
  state = {
    getOTPCalled: false,
  };

  canGetOTP = () => {
    const { dirty, errors, mobile } = this.props;
    if (!dirty) return false;
    if (mobile.length && !errors.mobile) return true;
    return false;
  };

  getOTP = async () => {
    const { mobile } = this.props;
    const { setFieldError, setFieldTouched } = this.props;
    const resp = await NetworkService.getOTP(mobile);
    if (!resp.ok) {
      const body = await resp.json();
      setFieldError("otp", printError(body));
      setFieldTouched("otp", true, false);
    }
    this.setState({ getOTPCalled: true });
  };

  render() {
    const {
      field,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      prevoting,
    } = this.props;
    const { getOTPCalled } = this.state;
    return (
      <FormGroup>
        <Label htmlFor={field}>一次性密碼(OTP)</Label>
        <InputGroup>
          <Input
            id={field}
            placeholder="輸入短訊收到的一次性密碼"
            value={values[field]}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
          />
          <InputGroupAddon addonType="append">
            <Button
              type="button"
              color={getOTPCalled ? "secondary" : "success"}
              disabled={prevoting || !this.canGetOTP()}
              onClick={this.getOTP}
            >
              領取OTP
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <FormText>
          {prevoting
            ? "尚未接受投票，不會發出短訊(SMS)"
            : "請確保你輸入的手提電話能夠接收短訊(SMS)，如未能收到OTP請於30分鐘後重新領取"}
          {/* 測試期間OTP不會經SMS發出，請按領取後繼續投票 */}
        </FormText>
        <Error field="otp" errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default OTP;
