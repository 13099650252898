import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import DebugNav from "./components/DebugNav";
import Header from "./components/Header";
import Footer from "./components/Footer";

import CodeVoting from "./pages/CodeVoting";
import Home from "./pages/Home";
import Voting from "./pages/Voting";

const App = () => {
  return (
    <Container className="App">
      <Header />
      <Router>
        <>
          <DebugNav />
          <Switch>
            <Route exact path="/" component={Home} />
            {/* comment these two in non-campaign period */}
            {/* <Route path="/voting" component={Voting} />
            <Route exact path="/g" component={CodeVoting} /> */}
            <Route path="*" component={Home} />
          </Switch>
        </>
      </Router>
      <Footer />
    </Container>
  );
};

export default App;
