import React, { PureComponent } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Row, Col } from "reactstrap";
import { Alert, Button, Form, FormGroup } from "reactstrap";

import Constant from "../constant";
import printError from "../errors";
import NetworkService from "../NetworkService";
import PreVotingBanner from "../components/PreVotingBanner";

import AgeGroup from "../form/AgeGroup";
import Capcity from "../form/Capcity";
import Choice from "../form/Choice";
import DisplayFormikState from "../form/DisplayFormikState";
import Education from "../form/Education";
import Gender from "../form/Gender";
import Level from "../form/Level";
import Mobile from "../form/Mobile";
import OTP from "../form/OTP";
import Reason from "../form/Reason";
import School from "../form/School";

const initialValues = {
  mobile: "",
  otp: "",
  gender: "",
  ageGroup: "",
  school: "",
  capacity: "",
  education: "",
  level: "",
  choice: "",
  reason: "",
};
const validationSchema = Yup.object().shape({
  mobile: Yup.string()
    .trim()
    .matches(Constant.MOBILE_REGEX, {
      message: "無效的手提電話號碼",
      excludeEmptyString: true,
    })
    .required("請輸入手提電話號碼"),
  otp: Yup.string()
    .trim()
    .matches(Constant.OTP_REGEX, {
      message: "無效的一次性密碼",
      excludeEmptyString: true,
    })
    .required("請輸入一次性密碼"),
  gender: Yup.string().required("請選擇性別"),
  ageGroup: Yup.number().integer().required("請選擇年齡組別"),
  school: Yup.object().when("capacity", {
    is: "其他",
    otherwise: Yup.object().required('請選擇所屬學校，或選擇"其他"'),
  }),
  capacity: Yup.string().required("請選擇投票人身份"),
  education: Yup.object().when("capacity", {
    is: "學生",
    then: Yup.object().required("請輸入教育程度"),
  }),
  level: Yup.object().when(["capacity", "education"], {
    is: (capacity, education) =>
      capacity === "學生" && education && education.value !== 3,
    then: Yup.object().required("請輸入年級"),
  }),
  choice: Yup.number().integer().required("請選擇年度歷史人物"),
  reason: Yup.string().trim(),
});

class MyForm extends PureComponent {
  render() {
    const {
      errors,
      values,
      touched,
      dirty,
      handleSubmit,
      handleReset,
      isSubmitting,
      submitError,
      submitted,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Mobile field="mobile" {...this.props} />
        <OTP
          field="otp"
          {...this.props}
          mobile={values.mobile}
          prevoting={Constant.PRE_VOTING}
        />
        <Gender field="gender" {...this.props} />
        <AgeGroup field="ageGroup" {...this.props} />
        <Capcity field="capacity" {...this.props} />
        {touched.capacity && values.capacity !== "其他" ? (
          <School field="school" {...this.props} />
        ) : null}
        {values.capacity === "學生" ? (
          <Education field="education" {...this.props} />
        ) : null}
        {values.education.label === "中學" ||
        values.education.label === "小學" ? (
          <Level
            field="level"
            {...this.props}
            primary={values.education.label === "小學"}
          />
        ) : null}
        <Choice field="choice" {...this.props} />
        <Reason field="reason" {...this.props} />
        <FormGroup>
          {submitted ? (
            <Alert color="success">感謝你的投票！</Alert>
          ) : (
            <>
              <Button
                color="danger"
                type="button"
                outline
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
                style={{ marginRight: "0.5em" }}
              >
                重設
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || Constant.PRE_VOTING}
              >
                提交
              </Button>
              {Object.keys(errors).length > 0 && (
                <div className="FormError">{"表格有誤，請檢視並重新輸入"}</div>
              )}
              {submitError && <div className="FormError">{submitError}</div>}
            </>
          )}
        </FormGroup>
        <DisplayFormikState {...this.props} />
      </Form>
    );
  }
}

class Voting extends PureComponent {
  state = {
    submitError: undefined,
    submitted: false,
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const payload = {
      ...values,
      // pick `label` field for select inputs
      school: values.school.label,
      education: values.education.label,
      level: values.level.label,
      type: "otp",
    };
    const resp = await NetworkService.castVote(payload);

    if (!resp.ok) {
      const body = await resp.json();
      console.error(body);
      this.setState({
        submitError: printError(body),
      });

      // setFieldError("otp", printError(body));
      // setFieldTouched("otp", true, false);
      setSubmitting(false);
    } else {
      this.setState({
        submitted: true,
      });
    }
  };

  render() {
    return (
      <>
        {Constant.PRE_VOTING && <PreVotingBanner />}
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              children={(props) => <MyForm {...props} {...this.state} />}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default Voting;
