import React from "react";

export default ({ thumb, src, style, alt = "" }) => {
  return (
    <div className="poster">
      <a target="_blank" rel="noopener noreferrer" href={src}>
        <img
          style={{
            width: "100%",
            boxShadow: "10px 10px 20px -5px rgba(0,0,0,0.75)",
            ...style,
          }}
          alt={alt}
          src={thumb}
          title="點擊放大"
        />
      </a>
    </div>
  );
};
