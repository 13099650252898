import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import Constant from "../constant";
import Poster from "../components/Poster";
import poster_thumb from "../images/poster_thumb.jpg";
import poster_full from "../images/poster_full.jpg";
import home1_thumb from "../images/home1_thumb.jpg";
import home1_full from "../images/home1_full.jpg";
import home2_thumb from "../images/home2_thumb.jpg";
import home2_full from "../images/home2_full.jpg";

const Completed = (
  <Row>
    <Container
      style={{
        top: 0,
        backgroundColor: "var(--accent-dark)",
        color: "#ffffff",
        fontWeight: 900,
        marginBottom: ".5rem",
      }}
      className="text-center"
    >
      <h2>網上投票經已完滿結束，多謝支持</h2>
      結果將於點票後上載至本中心網站，如有問題，歡迎致電查詢
    </Container>
  </Row>
);

const Home = (props) => {
  useEffect(() => {
    document.body.setAttribute("role", "home");

    return () => {
      document.body.setAttribute("role", "");
    };
  }, []);

  return (
    <>
      {Constant.POST_VOTING && Completed}
      <Row>
        {/* left hand side (bottom on mobile) */}
        <Col
          md={{ size: 6 }}
          lg={{ size: 5 }}
          xl={{ size: 4 }}
          className="order-last order-md-first text-center"
          style={{ marginBottom: "1rem" }}
        >
          <Poster thumb={poster_thumb} src={poster_full} alt="海報" />
        </Col>
        {/* right hand side (top on mobile) */}
        <Col
          md={{ size: 6 }}
          lg={{ size: 7 }}
          xl={{ size: 8 }}
          style={{ marginBottom: "1rem" }}
        >
          {/* row with centered content */}
          <Row>
            <Col xl={{ size: 11 }} className="mx-auto">
              <h3 style={{ color: "var(--accent-dark)", fontWeight: "700" }}>
                立即為「2020年度中國歷史人物選舉」投下你的一票
              </h3>

              <Link to="/voting">
                <Button
                  style={{
                    backgroundColor: "var(--accent-dark)",
                    borderColor: "var(--accent-dark)",
                    color: "#ffffff",
                    fontWeight: "bold",
                    marginBottom: ".5rem",
                  }}
                  disabled={Constant.POST_VOTING}
                >
                  立即投票
                </Button>
              </Link>
              <div
                className="disclaimer"
                style={{
                  display: Constant.POST_VOTING ? "none" : "block",
                  marginBottom: ".5rem",
                }}
              >
                輸入的手提電話只供是次活動投票之用（發出OTP和確保選票的唯一性），活動完結後會立即銷毁
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={{ size: 11 }} className="mx-auto">
              <h4 style={{ color: "var(--accent-dark)", fontWeight: "700" }}>
                活動回顧：
              </h4>
            </Col>
            <Col lg={{ size: 6 }} className="text-center">
              <Poster
                thumb={home1_thumb}
                src={home1_full}
                style={{ marginBottom: "0.5rem" }}
                alt="2018年度"
              />
              <div className="disclaimer">2018年度</div>
            </Col>
            <Col lg={{ size: 6 }} className="text-center">
              <Poster
                thumb={home2_thumb}
                src={home2_full}
                style={{ marginBottom: "0.5rem" }}
                alt="2019年度"
              />
              <div className="disclaimer">2019年度</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Home;
