import React from "react";
import { FormGroup, Label, Input, FormText } from "reactstrap";
import Error from "./Error";

const Mobile = ({
  field,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => (
  <FormGroup>
    <Label htmlFor={field}>手提電話</Label>
    <Input
      id={field}
      placeholder="請輸入可以接收短訊的手提電話號碼"
      type="tel"
      value={values[field]}
      onChange={handleChange}
      onBlur={handleBlur}
    />
    <FormText className="disclaimer">
      輸入的手提電話只供是次活動投票之用(發出OTP和確保選票的唯一性)，活動完結後會立即銷毁
    </FormText>
    <FormText>
      香港區以外手提電話號碼，請加字首國家號碼：
      <br />
      內地<code>+86</code> 澳門<code>+885</code> 台灣<code>+886</code>
      <br />
      新加坡<code>+65</code> 英國<code>+44</code> 澳洲<code>+61</code> 加拿大
      <code>+1</code>
    </FormText>
    <Error field={field} errors={errors} touched={touched} />
  </FormGroup>
);

export default Mobile;
