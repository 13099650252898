function padZeros(num, len) {
  var s = num + "";
  while (s.length < len) s = "0" + s;
  return s;
}

function getISODate(date) {
  return (
    "" +
    date.getFullYear() +
    "-" +
    padZeros(date.getMonth() + 1, 2) +
    "-" +
    padZeros(date.getDate(), 2)
  );
}

function getTime(date) {
  return (
    "" + padZeros(date.getHours(), 2) + ":" + padZeros(date.getMinutes(), 2)
  );
}

export default (body) => {
  switch (body.message) {
    // /otp
    case "otp.exist": {
      const expires = new Date(body.expiresAt);
      return `已發送OTP到該手提電話，有效至 ${getTime(expires)}`;
    }
    // /votes, /otp
    case "vote.exist": {
      const created = new Date(body.createdAt);
      return `該手提電話已於 ${getISODate(created)} ${getTime(created)} 投票`;
    }
    // /votes
    case "otp.not_found": {
      return "找不到OTP，請重新領取";
    }
    // /votes
    case "otp.expired": {
      const expires = new Date(body.expiresAt);
      return `OTP已過期，有效至 ${getTime(expires)}`;
    }
    // /votes
    case "otp.mismatch": {
      return "OTP不正確，請重新輸入";
    }
    // /votes
    case "validation.error": {
      return "表格驗證錯誤, 請通知管理員";
    }
    // /codes
    case "code.not_found": {
      return "找不到短代碼，請重新輸入";
    }
    default:
      return body.message;
  }
};
