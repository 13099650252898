import React from "react";
import { FormGroup, Label, Input, FormText, Row, Col } from "reactstrap";
import Error from "./Error";
import "./Choice.css";

import choice1_img from "../images/1.png";
import choice2_img from "../images/2.png";
import choice3_img from "../images/3.png";
import choice4_img from "../images/4.png";
import choice5_img from "../images/5.png";

const choices = [
  {
    value: 1,
    name: "張衡<br>（東漢）",
    image: choice1_img,
  },
  {
    value: 2,
    name: "祖沖之<br>（南北朝）",
    image: choice2_img,
  },
  {
    value: 3,
    name: "沈括<br>（北宋）",
    image: choice3_img,
  },
  {
    value: 4,
    name: "郭守敬<br>（元朝）",
    image: choice4_img,
  },
  {
    value: 5,
    name: "李時珍<br>（明朝）",
    image: choice5_img,
  },
];

const Choice = ({
  field,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => (
  <FormGroup>
    <Row form style={{ margin: "0" }}>
      <Label htmlFor={field}>年度歷史人物候選人</Label>
    </Row>
    <Row form style={{ margin: "0" }}>
      <FormText>
        請選擇下列一位心儀的年度中國歷史人物，然後按「提交」，即完成投票！
        <br />* 有關五位候選人物簡介，請參閱本中心網頁：
        <br className="rwd-break" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.cnhe-hk.org/年度中國歷史人物選舉2020暨專題研習報告比賽相關/"
        >
          www.cnhe-hk.org/年度中國歷史人物選舉2020暨專題研習報告比賽相關/
        </a>
      </FormText>
    </Row>
    <Row form style={{ margin: "0" }}>
      {choices.map((choice, idx) => {
        // eslint-disable-next-line
        const checked = values.choice == choice.value;
        // const src = checked
        //   ? "./images/radio_checked.svg"
        //   : "./images/radio_unchecked.svg";
        const src = checked
          ? "./images/check_box_checked.svg"
          : "./images/check_box_unchecked.svg";
        return (
          <Col lg={4} xs={6} key={idx}>
            <Label check className="ChoiceElement">
              <Input
                type="radio"
                name={field}
                value={choice.value}
                checked={checked}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div className="card">
                <img src={choice.image} alt={choice.name} className="poster" />
                <div className="label">
                  <img src={src} alt="check box" className="radio" />
                  <div dangerouslySetInnerHTML={{ __html: choice.name }} />
                </div>
              </div>
            </Label>
          </Col>
        );
      })}
    </Row>
    <Error field={field} errors={errors} touched={touched} />
  </FormGroup>
);

export default Choice;
